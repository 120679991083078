// Increment this when format changes significantly
const VERSION = 1;

class DandyLabelExporter {
  parse(attributes, object) {

    // Iterate over the valid meshes in the object
    function traverseMeshes(cb) {
      object.traverse(function (child) {
        if (child.isMesh === true) {
          const mesh = child;
          const geometry = mesh.geometry;

          if (geometry.isBufferGeometry !== true) {
            throw new Error(
              'THREE.PLYExporter: Geometry is not of type THREE.BufferGeometry.'
            );
          }

          if (geometry.hasAttribute('position') === true) {
            cb(mesh, geometry);
          }
        }
      });
    }

    let labelNames = [];
    object.traverse(function (child) {
      if (child.isMesh === true) {
        const mesh = child;
        const geometry = mesh.geometry;

        if (geometry.isBufferGeometry !== true) {
          throw new Error(
            'THREE.PLYExporter: Geometry is not of type THREE.BufferGeometry.'
          );
        }

        const vertices = geometry.getAttribute('position');
        Object.keys(geometry.attributes)
          .filter((attrName) => attrName.indexOf('_mask') !== -1)
          .forEach((attrName) => {
            const labelName = attrName.replace('_mask', '');
            if (!labelNames.includes(labelName)) labelNames.push(labelName);
          });

      }
    });

    const result = {
      version: VERSION,
      attributes,
      vertexMasks: {},
    };
    labelNames.forEach((labelName) => {
      result.vertexMasks[labelName] = [];
    });

    traverseMeshes(function (mesh, geometry) {
      const vertices = geometry.getAttribute('position');

      for (let i = 0, l = vertices.count; i < l; i++) {
        labelNames.forEach((labelName) => {
          const maskAttr = geometry.getAttribute(labelName + '_mask');
          if (maskAttr) {
            if (maskAttr.getX(i)) {
              result.vertexMasks[labelName].push(i);
            }
          }
        });

      }

    });

    return JSON.stringify(result);
  }
}

export { DandyLabelExporter };
