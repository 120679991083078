import React from 'react';

import './text-checkbox.css';

export default function TextCheckbox({
  checked,
  onChange,
  className,
  text,
  ...rest
}) {
  className = className ? `text-checkbox ${className}` : 'text-checkbox';
  if (checked) {
    className += ' checked';
  }

  function handleClick(event) {
    event.stopPropagation();
    onChange && onChange(event);
  }

  return (
    <span className={className} onClick={handleClick} {...rest}>
      {text}
    </span>
  );
}
