// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_shortcutIcon__iMxSB {
  display: inline-block;
  min-width: 1em;
  height: 1em;
  border: 1px solid darkgrey;
  border-radius: 3px;
  text-align: center;
  line-height: 100%;
  margin: 0 0.5em 0 0.35em;
}
`, "",{"version":3,"sources":["webpack://./src/ShortcutIcon/styles.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,cAAc;EACd,WAAW;EACX,0BAA0B;EAC1B,kBAAkB;EAClB,kBAAkB;EAClB,iBAAiB;EACjB,wBAAwB;AAC1B","sourcesContent":[".shortcutIcon {\n  display: inline-block;\n  min-width: 1em;\n  height: 1em;\n  border: 1px solid darkgrey;\n  border-radius: 3px;\n  text-align: center;\n  line-height: 100%;\n  margin: 0 0.5em 0 0.35em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"shortcutIcon": `styles_shortcutIcon__iMxSB`
};
export default ___CSS_LOADER_EXPORT___;
