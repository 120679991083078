// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_green__IPwt9 {
  width: 1em;
  height: 1em;
  display: inline;
  background-color: green;
}

.styles_dropdownList__-0mvq {
  padding: 0;
  margin: 0;
  width: 100%;
  border-spacing: 0;
  height: 400px;
  overflow-y: scroll;
}

.styles_dropdownList__-0mvq div {
  padding: 0.2em;
  display: flex;
  align-items: center;

  height: 2.5em;

  box-sizing: border-box;
  border: 1px solid darkgrey;
  border-bottom: none;
}

.styles_dropdownList__-0mvq div:last-child {
  border-bottom: 1px solid darkgrey;
}

.styles_dropdownList__-0mvq div:first-child {
  border-bottom: 1px solid darkgrey;
}

.styles_dropdownList__-0mvq .styles_labelsHeader__p-89f {
  border-left: none;
  border-right: none;
  border-top: none;
  height: 2em;
  display: flex;
}

.styles_spacer__KTOsm {
  flex-grow: 1;
}

.styles_label__NjMmj {
  flex-grow: 1;
}

.styles_colorSquare__m9EhJ {
  width: 1.5em;
  height: 1.5em;
  border: 1px solid darkgrey;
}

.styles_dropdownList__-0mvq div:not(.styles_labelsHeader__p-89f):hover {
  color: white;
  background-color: darkgrey;
}

.styles_selected__aID88 {
  background-color: #505050;
  color: white;
}

.styles_attributes__PvXlE {
  margin-left: 5px;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/LabelSelect/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,mBAAmB;;EAEnB,aAAa;;EAEb,sBAAsB;EACtB,0BAA0B;EAC1B,mBAAmB;AACrB;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".green {\n  width: 1em;\n  height: 1em;\n  display: inline;\n  background-color: green;\n}\n\n.dropdownList {\n  padding: 0;\n  margin: 0;\n  width: 100%;\n  border-spacing: 0;\n  height: 400px;\n  overflow-y: scroll;\n}\n\n.dropdownList div {\n  padding: 0.2em;\n  display: flex;\n  align-items: center;\n\n  height: 2.5em;\n\n  box-sizing: border-box;\n  border: 1px solid darkgrey;\n  border-bottom: none;\n}\n\n.dropdownList div:last-child {\n  border-bottom: 1px solid darkgrey;\n}\n\n.dropdownList div:first-child {\n  border-bottom: 1px solid darkgrey;\n}\n\n.dropdownList .labelsHeader {\n  border-left: none;\n  border-right: none;\n  border-top: none;\n  height: 2em;\n  display: flex;\n}\n\n.spacer {\n  flex-grow: 1;\n}\n\n.label {\n  flex-grow: 1;\n}\n\n.colorSquare {\n  width: 1.5em;\n  height: 1.5em;\n  border: 1px solid darkgrey;\n}\n\n.dropdownList div:not(.labelsHeader):hover {\n  color: white;\n  background-color: darkgrey;\n}\n\n.selected {\n  background-color: #505050;\n  color: white;\n}\n\n.attributes {\n  margin-left: 5px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green": `styles_green__IPwt9`,
	"dropdownList": `styles_dropdownList__-0mvq`,
	"labelsHeader": `styles_labelsHeader__p-89f`,
	"spacer": `styles_spacer__KTOsm`,
	"label": `styles_label__NjMmj`,
	"colorSquare": `styles_colorSquare__m9EhJ`,
	"selected": `styles_selected__aID88`,
	"attributes": `styles_attributes__PvXlE`
};
export default ___CSS_LOADER_EXPORT___;
