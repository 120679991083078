import { useCallback, useEffect } from 'react';

function useKeyboardShortcuts(keyCodes, onPress) {
  // useCallback prevents function from being redefined every time component is rendered
  const memoizedKeyUpListener = useCallback(
    (e) => {
      const { code } = e;
      if (!keyCodes.includes(code)) return;
      onPress(code);
    },
    [keyCodes, onPress]
  );

  useEffect(() => {
    window.addEventListener('keyup', memoizedKeyUpListener, true);
    return () =>
      window.removeEventListener('keyup', memoizedKeyUpListener, true);
  }, [memoizedKeyUpListener]);
}

export default useKeyboardShortcuts;
