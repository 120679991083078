/**
 * See https://codeworkshop.dev/blog/2020-04-03-adding-orbit-controls-to-react-three-fiber/
 */

import React, { useEffect, useRef } from 'react';
import { extend, useFrame, useThree } from '@react-three/fiber';

import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';

extend({ TrackballControls });

const CameraControls = (props) => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the TrackballControls component.
  // https://threejs.org/docs/#examples/en/controls/TrackballControls
  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();

  useFrame((state) => controls.current.update());

  useEffect(() => {
    if (controls.current && props.onChange) {
      if (!controls.current.hasEventListener('change', props.onChange)) {
        controls.current.addEventListener('change', props.onChange);
      }
    }
  });

  return (
    <trackballControls ref={controls} args={[camera, domElement]} {...props} />
  );
};

export default CameraControls;
