class DandyLabelLoader {

  constructor(setAttributes, setVertexMasks) {
    this.setAttributes = setAttributes;
    this.setVertexMasks = setVertexMasks;
  }

  load(data, onLoad, onProgress, onError) {
    console.log('loading label data', data);
    this.setAttributes(data.attributes || {});
    this.setVertexMasks(data.vertexMasks || {});
    onLoad(data);
  }

}

export { DandyLabelLoader };
