function makeResponseSummary(status, url, xhr) {
  let response = {};
  let json_parsed = false;
  const response_text_type = xhr.responseType == 'json' || xhr.responseType == '' || xhr.responseType == 'text';

  if (response_text_type && xhr.responseText) {
    try {
      response = JSON.parse(xhr.responseText);
      json_parsed = true;
    } catch (e) {
    }
  }
  response['status'] = status;
  response['statusText'] = xhr.statusText;
  response['url'] = url;
  response['responseType'] = xhr.responseType;
  if (response_text_type && !json_parsed && xhr.responseText) {
    response['responseText'] = xhr.responseText;
  }

  return response;
}

class CloudBucket {
  constructor() {
    this.backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
  }

  async downloadBucketObject(fileName, progressCallback = () => {}) {
    /*
    let result = await this.makeDownloadRequest(fileName, progressCallback);
    */
    const downloadData = await this.makeDownloadUrlRequest(fileName);
    const {responseType, signedUrl} = JSON.parse(downloadData);
    const result = await this.makeDownloadViaUrlRequest(responseType, signedUrl, progressCallback);
    return result;
  }

  makeDownloadUrlRequest(fileName) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/download_url/${encodeURIComponent(
        fileName
      )}`;
      xhr.open('GET', u);
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.send();
    }).then(function (xhr) {
      return xhr.response;
    });
  }

  makeDownloadViaUrlRequest(responseType, signedUrl, progressCallback) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open('GET', signedUrl);
      xhr.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, signedUrl, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: signedUrl,
        });
      };
      xhr.responseType = responseType;
      xhr.send();
    }).then(function (xhr) {
      let result = responseType === 'blob' ? window.URL.createObjectURL(xhr.response) : xhr.response;
      return result;
    });
  }

  makeDownloadRequest(fileName, progressCallback) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/download/${encodeURIComponent(
        fileName
      )}`;
      xhr.open('GET', u);
      xhr.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.responseType = 'blob';
      xhr.send();
    }).then(function (xhr) {
      let result = window.URL.createObjectURL(xhr.response);
      return result;
    });
  }

  async uploadObjectToBucket(object, objName, progressCallback = () => {}) {
    /*
    let upload_request = await this.makeUploadRequest(object, objName, progressCallback);
    */
    const uploadData = await this.makeUploadUrlRequest(objName);
    const {contentType, signedUrl} = JSON.parse(uploadData);
    const upload_request = await this.makeUploadViaUrlRequest(object, contentType, signedUrl, progressCallback);
    return upload_request;
  }

  makeUploadUrlRequest(fileName) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      const u = `${backendApiUrl}/upload_url/${encodeURIComponent(
        fileName
      )}`;
      xhr.open('GET', u);
      xhr.onload = function () {
        if (this.status === 200) {
          resolve(xhr);
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.send();
    }).then(function (xhr) {
      return xhr.response;
    });
  }

  makeUploadViaUrlRequest(object, contentType, signedUrl, progressCallback) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.upload.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve();
        } else {
          reject(makeResponseSummary(this.status, signedUrl, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: signedUrl,
        });
      };
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', contentType);
      xhr.send(object);
    });
  }

  makeUploadRequest(object, objName, progressCallback) {
    let backendApiUrl = this.backendApiUrl;
    return new Promise(function (resolve, reject) {
      const u = `${backendApiUrl}/upload/${encodeURIComponent(
        objName
      )}`;
      let xhr = new XMLHttpRequest();
      xhr.withCredentials = true;
      xhr.upload.onprogress = function (event) {
        progressCallback(event.loaded / event.total);
      };
      xhr.onload = function () {
        if (this.status === 200) {
          resolve();
        } else {
          reject(makeResponseSummary(this.status, u, xhr));
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText,
          url: u,
        });
      };
      xhr.open('POST', u);
      xhr.send(object);
    });
  }
}

export default CloudBucket;
