// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__XeFx\\+ {
  border: 1px solid darkgrey;
  border-radius: 0.5em;
  margin: 0.5em;
  padding: 1em;
  position: absolute;
  top: 0;
  right: 0;
  background-color: white;
  z-index: 5;
}

.styles_container__XeFx\\+ p {
  margin: 0;
  text-align: right;
}

.styles_invisible__A8ijK {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/HelpKey/styles.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".container {\n  border: 1px solid darkgrey;\n  border-radius: 0.5em;\n  margin: 0.5em;\n  padding: 1em;\n  position: absolute;\n  top: 0;\n  right: 0;\n  background-color: white;\n  z-index: 5;\n}\n\n.container p {\n  margin: 0;\n  text-align: right;\n}\n\n.invisible {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__XeFx+`,
	"invisible": `styles_invisible__A8ijK`
};
export default ___CSS_LOADER_EXPORT___;
