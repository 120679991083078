import React, { useRef, useEffect, useState } from 'react';

import { DandyPLYLoader } from './DandyPLYLoader';
//import { DandyPLYExporter } from './DandyPLYExporter';
import { DandyLabelLoader } from './DandyLabelLoader';
import { DandyLabelExporter } from './DandyLabelExporter';

import options from '../config.json';
import { BufferAttribute, Color } from 'three';
import { VertexFaceIndex } from './VertexFaceIndex';

import styles from './styles.module.css';
import ProgressBar from '../ProgressBar';
import useDocumentTitle from '../useDocumentTitle';

function ImportExport({ attributes, setAttributes, vertexMasks, setVertexMasks, mesh, geometry, setGeometry, setFaceIndex, bucket }) {
  let [plyFile, setPlyFile] = useState(null);
  const [message, setMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useDocumentTitle(plyFile ? `${plyFile.name} - Mesh Labeler` : 'Mesh Labeler');

  const triggerDownload = (filename) => {
    if (!bucket) return;

    // Load the file, call setPlyFile on it
    setLoading(true);
    setMessage('Downloading...');

    bucket
      .downloadBucketObject(filename + '.json')
      .then((data) => {
        const loader = new DandyLabelLoader(setAttributes, setVertexMasks);
        loader.load(data, () => {
          bucket
          .downloadBucketObject(filename, setProgress)
          .then((url) => {
            setMessage('Success!');
            setProgress(0);
            setPlyFile({ url, name: filename });
          })
          .catch((e) => setMessage(e.message))
          .finally(() => setLoading(false));
        })
      })
      .catch((e) => setMessage(e.message))
      .finally(() => setLoading(false));
  }
  const location = window.location;

  useEffect(() => {
    if (!bucket) return;
    const url = new URL(location.href);
    if (url.pathname.startsWith('/load/')) {
      const filename = decodeURIComponent(url.pathname.slice('/load/'.length));
      console.log('Loading', filename);
      setTimeout(() => triggerDownload(filename), 100);
    }
  }, [location, bucket]);

  useEffect(() => {
    if (plyFile) {
      // Disable texcoord loading, otherwise PLYLoader will convert
      // to unindexed geometry
      const loader = new DandyPLYLoader();
      loader.setPropertyNameMapping({'texcoord': 'null'});
      loader.load(plyFile.url, (geometry) => {

        if (!geometry.hasAttribute('normal')) {
          geometry.computeVertexNormals();
        }

        if (!geometry.hasAttribute('original_color')) {
          const colorAttributeCopy = geometry.getAttribute('color').clone();
          geometry.setAttribute('original_color', colorAttributeCopy);
        }

        options.labels.forEach(({ id }) => {
          const attrName = `${id}_mask`;

          if (!geometry.hasAttribute(attrName)) {
            const vertexCount = geometry.attributes.position.count;
            const attrArray = new Uint8Array(vertexCount);
            const maskAttr = new BufferAttribute(attrArray, 1);
            geometry.setAttribute(attrName, maskAttr);
          } else {
            console.log(`${attrName} attribute loaded`);
          }

        });

        const index = new VertexFaceIndex(geometry);
        index.build();

        setFaceIndex(index);

        setGeometry(geometry);
      });
    }
  }, [plyFile, setGeometry, setFaceIndex, vertexMasks]);

  useEffect(() => {
    if (!geometry || !vertexMasks) {
      return;
    }

    setTimeout(() => {
      const colorAttr = geometry.getAttribute('color');
      options.labels.forEach(({ id, color }) => {
        const markerColor = new Color(color);
        const attrName = `${id}_mask`;
        const maskAttr = geometry.getAttribute(attrName);
        (vertexMasks[id] || []).forEach((i) => {
          colorAttr.setXYZ(i, markerColor.r, markerColor.g, markerColor.b);
          maskAttr.setX(i, 1);
        });
      });

    }, 1000)
  }, [vertexMasks, geometry]);

  const getDownloadData = () => {
    const exporter = new DandyLabelExporter();
    const geometry = mesh.current.geometry;

    const allMarkerColors = geometry.getAttribute('color').clone();

    // Erase marker colors
    const originalColorAttr = geometry.getAttribute('original_color').clone();
    geometry.setAttribute('color', originalColorAttr);

    // Redraw marker colors
    const colorAttr = geometry.getAttribute('color');
    options.labels.forEach(({ id, color }) => {
      const markerColor = new Color(color);
      const maskAttr = geometry.getAttribute(`${id}_mask`);
      for (let i = 0; i < maskAttr.count; i++) {
        if (maskAttr.getX(i) > 0) {
          colorAttr.setXYZ(i, markerColor.r, markerColor.g, markerColor.b);
        }
      }
    });

    // Download the file
    const data = exporter.parse(attributes, mesh.current);

    // Restore marker colors
    geometry.setAttribute('color', allMarkerColors);
    return data;
  };

  // If a critical error happens, make the user very aware of it.
  function displayError(context, e) {
    let msg;
    if (e instanceof Error) {
      msg = JSON.stringify(e.toString());
    } else {
      msg = JSON.stringify(e, null, 1);
    }

    msg = "An Error occurred during " + context + ": " + msg;
    setMessage(msg);
    alert(msg);
  }

  return (
    <>
      {plyFile && (
        <>
          <title>{plyFile.name} - Mesh Labeler</title>
          <div className={styles.filenameDisplay}>
            <span>{plyFile.name}</span>
          </div>
          <div>
            <button
              disabled={loading}
              onClick={() => {
                setLoading(true);
                setMessage('Uploading...');
                setTimeout(() => {
                  const description = "Export to Google Bucket";
                  try {
                    const fileData = getDownloadData();
                    bucket
                      .uploadObjectToBucket(
                        fileData,
                        plyFile.name + '.json',
                        setProgress
                      )
                      .then(() => {
                        setMessage(description + ' Successful!');
                        setProgress(0);
                      })
                      .catch((e) => displayError(description, e))
                      .finally(() => setLoading(false));
                  } catch(e) {
                    // an error occured while preparing the data to upload
                    displayError(description, e);
                  }
                }, 100);
              }}
            >
              Export to Google Bucket
            </button>
          </div>
        </>
      )}
      {message && (
        <div className={styles.bucketMessage}>
          <p>{message}</p>
        </div>
      )}
      {loading && <ProgressBar progress={progress} />}
    </>
  );
}

export default ImportExport;
