// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__oxRMk {
  height: 1.5em;
  width: 100%;
  background-color: lightgray;
  border-radius: 1em;
  margin-bottom: 1em;
  margin-top: 0;
}

.styles_filler__\\+hJjC {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: blue;
  border-radius: inherit;
  text-align: right;
}

.styles_label__eSrqd {
  padding: 5px;
  color: white;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/ProgressBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;EAClB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,sBAAsB;EACtB,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".container {\n  height: 1.5em;\n  width: 100%;\n  background-color: lightgray;\n  border-radius: 1em;\n  margin-bottom: 1em;\n  margin-top: 0;\n}\n\n.filler {\n  height: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n  align-items: center;\n  background-color: blue;\n  border-radius: inherit;\n  text-align: right;\n}\n\n.label {\n  padding: 5px;\n  color: white;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__oxRMk`,
	"filler": `styles_filler__+hJjC`,
	"label": `styles_label__eSrqd`
};
export default ___CSS_LOADER_EXPORT___;
