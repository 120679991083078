import React from 'react';
import ShortcutIcon from '../ShortcutIcon';
import useLocalStorage from '../useLocalStorage';

import styles from './styles.module.css';

function HelpKey({ showModifier }) {
  const [visible, setVisible] = useLocalStorage('showHelpKey', true);
  const shortcutPrefix = showModifier ? "^" : "";

  return (
    <div className={styles.container}>
      <p>
        Keyboard shortcuts{' '}
        <button onClick={() => setVisible(!visible)}>
          {visible ? 'Hide' : 'Show'}
        </button>
      </p>
      <div className={`${visible ? '' : styles.invisible}`}>
        <br />
        <p>
          Number keys <ShortcutIcon character={`${shortcutPrefix}1`} />-
          <ShortcutIcon character={`${shortcutPrefix}9`} />: change active label
        </p>
        <p>
          <ShortcutIcon character={`${shortcutPrefix}-`} /> and <ShortcutIcon character={`${shortcutPrefix}+`} />/
          <ShortcutIcon character={`${shortcutPrefix}=`} />: change marker size
        </p>
        <p>
          <ShortcutIcon character={`${shortcutPrefix}0`} />: Toggle all labels visibility
        </p>
        <p>
          <ShortcutIcon character="Alt" />/<ShortcutIcon character="Option" />: Erase marker
        </p>
      </div>
    </div>
  );
}

export default HelpKey;
