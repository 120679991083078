// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_downloadButton__pkErX {
  margin-right: 1em;
}

.styles_filenameDisplay__ZM\\+6k {
  height: 20px;
  max-width: 320px;
  overflow-x: scroll;
  white-space: nowrap;
}

.styles_filenameTextInput__PakZP {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 300px;
}

.styles_bucketMessage__mfLUy p {
  width: 300px;
  font-size: 10pt;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/ImportExport/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,SAAS;AACX","sourcesContent":[".downloadButton {\n  margin-right: 1em;\n}\n\n.filenameDisplay {\n  height: 20px;\n  max-width: 320px;\n  overflow-x: scroll;\n  white-space: nowrap;\n}\n\n.filenameTextInput {\n  border: 1px solid #999;\n  padding: 0.5rem;\n  width: 300px;\n}\n\n.bucketMessage p {\n  width: 300px;\n  font-size: 10pt;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"downloadButton": `styles_downloadButton__pkErX`,
	"filenameDisplay": `styles_filenameDisplay__ZM+6k`,
	"filenameTextInput": `styles_filenameTextInput__PakZP`,
	"bucketMessage": `styles_bucketMessage__mfLUy`
};
export default ___CSS_LOADER_EXPORT___;
