// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__e-DWO {
  border: 1px solid darkgrey;
  border-radius: 0.5em;
  margin: 0.5em;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: white;
  min-width: 300px;
}

.styles_container__e-DWO > div {
  margin-bottom: 10px;
}

.styles_container__e-DWO > div:last-child {
  margin-bottom: 0;
}

.styles_markerSettings__-NdhZ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.styles_markerSettings__-NdhZ * {
  margin-right: 0.5em;
}

.styles_markerSettings__-NdhZ *:last-child {
  margin-right: 0;
}

.styles_markerSettings__-NdhZ p {
  margin-top: 0;
  margin-bottom: 0;
  flex-grow: 1;
}

.styles_markerSettings__-NdhZ button {
  width: 2em;
  height: 2em;
}
`, "",{"version":3,"sources":["webpack://./src/Controls/styles.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,oBAAoB;EACpB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,OAAO;EACP,MAAM;EACN,UAAU;EACV,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".container {\n  border: 1px solid darkgrey;\n  border-radius: 0.5em;\n  margin: 0.5em;\n  padding: 1em;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  position: absolute;\n  left: 0;\n  top: 0;\n  z-index: 5;\n  background-color: white;\n  min-width: 300px;\n}\n\n.container > div {\n  margin-bottom: 10px;\n}\n\n.container > div:last-child {\n  margin-bottom: 0;\n}\n\n.markerSettings {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n\n.markerSettings * {\n  margin-right: 0.5em;\n}\n\n.markerSettings *:last-child {\n  margin-right: 0;\n}\n\n.markerSettings p {\n  margin-top: 0;\n  margin-bottom: 0;\n  flex-grow: 1;\n}\n\n.markerSettings button {\n  width: 2em;\n  height: 2em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__e-DWO`,
	"markerSettings": `styles_markerSettings__-NdhZ`
};
export default ___CSS_LOADER_EXPORT___;
