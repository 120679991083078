import React, { useState } from 'react';

import styles from './styles.module.css';
import TextCheckbox from './text-checkbox.js';

import options from '../config.json';

import useKeyboardShortcuts from '../useKeyboardShortcuts';
import ShortcutIcon from '../ShortcutIcon';

const keyCodes = Array(9)
  .fill(0)
  .map((_, i) => `Digit${i + 2}`)
  .slice(0, Math.min(8, options.labels.length));

const attributesById = {};
options.attributes.forEach((attribute) => {
  attributesById[attribute.id] = attribute;
})

function LabelSelect({
  label,
  onChange,
  visibleLabels,
  setVisibleLabels,
  hideAllLabels,
  setHideAllLabels,
  isFilterFocused,
  setIsFilterFocused,
  attributes,
  setAttributes,
}) {
  // For dealing with filtering
  const [filterText, setFilterText] = useState("");

  const onFilterTextChange = (e) => {
    const newInput = e.currentTarget.value;
    setFilterText(newInput);
  }

  const filterTextPredicate = (value) => {
    if (!filterText) {
      return true;
    }
    return value.label.toLowerCase().indexOf(filterText.toLowerCase()) >= 0;
  }

  const shortcutPrefix = isFilterFocused ? "^" : "";

  function handleSelect(label) {
    if (label && !visibleLabels.includes(label)) {
      const newVal = visibleLabels.slice();
      newVal.push(label);
      setVisibleLabels(newVal);
    }
    onChange && onChange(label);
  }

  function handleAttributeChange(labelId, attributeId, attributeValue) {
    console.log('handleAttributeChange', labelId, attributeId, attributeValue);
    setAttributes({
      ...attributes,
      [labelId]: {
        ...attributes[labelId],
        [attributeId]: attributeValue,
      }
    })
  }

  useKeyboardShortcuts(['Digit1', ...keyCodes], (code) => {
    if (code === 'Digit1') {
      handleSelect(null);
    } else {
      handleSelect(options.labels.filter(filterTextPredicate)[keyCodes.indexOf(code)]);
    }
  });

  function toggleArrayElement(l, array, setFunction, e) {
    const _array = array.slice();
    const i = _array.indexOf(l);
    if (i >= 0) {
      _array.splice(i, 1);
    } else {
      _array.push(l);
    }
    setFunction(_array);
  }

  return (
    <div className={styles.dropdownList}>
      <div
        className={`option ${label ? '' : styles.selected}`}
        onClick={() => {
          handleSelect(null);
        }}
      >
        <ShortcutIcon character={`${shortcutPrefix}1`} />
        <span className={styles.label}>Move tool</span>
      </div>

      <div className={styles.labelsHeader}>
        <span>
          <input
            placeholder="Filter label classes"
            text={filterText}
            onChange={onFilterTextChange}
            onFocus={() => setIsFilterFocused(true)}
            onBlur={() => setIsFilterFocused(false)}
          />
        </span>
        <span className={styles.spacer}></span>
        <span>
          <ShortcutIcon character={`${shortcutPrefix}0`} />
          hide all
          <input type="checkbox"
            checked={hideAllLabels}
            onChange={() => setHideAllLabels(!hideAllLabels)} />
        </span>
      </div>

      {options.labels.filter(filterTextPredicate).map((l, index) => (
        <div
          key={l.label}
          className={`option ${l === label ? styles.selected : ''}`}
          onClick={() => {
            handleSelect(l);
          }}
        >
          {index <= 7 && <ShortcutIcon character={`${shortcutPrefix}${index + 2}`} />}
          <span key="label" className={styles.label}>{l.label}</span>
          <span key="attributes" className={styles.attributes}>
            {l.attributes?.map((attributeId, attributeIdx) => {
              const selectedValue = (attributes[l.id] ?? {})[attributeId] ?? undefined;
              return <span key={attributeIdx}>
                {attributesById[attributeId].label}:
                <select
                  name={`${l.id}-${attributeId}`}
                  onChange={(e) => {
                    handleAttributeChange(l.id, attributeId, e.currentTarget.value);
                  }}
                  value={selectedValue}
                >
                  {attributesById[attributeId].values.map((attributeVal, attributeValIdx) => {
                    return <option key={attributeValIdx} value={attributeVal}>{attributeVal}</option>;
                  })}
                </select>
              </span>;
            })}
          </span>
          <span>
            <TextCheckbox
              text={'show'}
              checked={visibleLabels.includes(l)}
              onChange={toggleArrayElement.bind(
                undefined,
                l,
                visibleLabels,
                setVisibleLabels
              )}
            ></TextCheckbox>
          </span>
          <span
            className={styles.colorSquare}
            style={{ backgroundColor: l.color }}
          />
        </div>
      ))}
    </div>
  );
}

export default LabelSelect;
