export default class MaskVerticesCache {
  constructor(labels) {
    this.labels = labels;

    labels.forEach((l) => {
      this[l.id] = new Set();
    });
  }

  reset(geometry) {
    geometry &&
      this.labels.forEach((l) => {
        // This might happen when we are loading new mesh
        this[l.id].clear();

        geometry
          .getAttribute(`${l.id}_mask`)
          .array.forEach((maskValue, index) => {
            if (maskValue !== 0) {
              this[l.id].add(index);
            }
          });
      });
  }

  listAll() {
    const result = new Set();
    this.labels.forEach(l => {
      this[l.id].forEach((v) => {
        result.add(v);
      });
    });
    return result;
  }

  mark(labelId, vertices) {
    vertices.forEach((v) => this[labelId].add(v));
  }

  clear(labelId, vertices) {
    vertices.forEach((v) => this[labelId].delete(v));
  }
}
