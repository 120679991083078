// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputDial button {
    margin-left: 0.25em;

    width: 1.5em;
    height: 1.5em;

    border-radius: 0;
    outline: none;
    text-align: center;
    vertical-align: middle;
    border-style: none;

    background-color: #ddd;
}

.inputDial button:hover {
    cursor: pointer;
    filter: brightness(85%);
}

.inputDial .raised {
    box-shadow: 2px 2px 12px -3px #333;
}
`, "",{"version":3,"sources":["webpack://./src/Controls/input-dial.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;IAEnB,YAAY;IACZ,aAAa;;IAEb,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;;IAElB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;AACtC","sourcesContent":[".inputDial button {\n    margin-left: 0.25em;\n\n    width: 1.5em;\n    height: 1.5em;\n\n    border-radius: 0;\n    outline: none;\n    text-align: center;\n    vertical-align: middle;\n    border-style: none;\n\n    background-color: #ddd;\n}\n\n.inputDial button:hover {\n    cursor: pointer;\n    filter: brightness(85%);\n}\n\n.inputDial .raised {\n    box-shadow: 2px 2px 12px -3px #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
