// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-checkbox {
  display: inline-block;
  text-decoration: line-through;
  margin-right: 3pt;
  -webkit-user-select: none;
          user-select: none;
}

.text-checkbox:hover {
  cursor: pointer;
  background-color: #333;
}

.text-checkbox.checked {
  text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/LabelSelect/text-checkbox.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,6BAA6B;EAC7B,iBAAiB;EACjB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".text-checkbox {\n  display: inline-block;\n  text-decoration: line-through;\n  margin-right: 3pt;\n  user-select: none;\n}\n\n.text-checkbox:hover {\n  cursor: pointer;\n  background-color: #333;\n}\n\n.text-checkbox.checked {\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
