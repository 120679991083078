import { useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";


// https://github.com/pmndrs/react-three-fiber/blob/master/markdown/recipes.md
export default function CameraRig(props) {
    const ref = useRef();
    const set = useThree(state => state.set);
    const { size } = useThree();
    // Make the camera known to the system
    useEffect(() => void set({ camera: ref.current }), []);

    useEffect(() => {
      ref.current.near = props.near;
      ref.current.updateProjectionMatrix();
    }, [ props.near ]);

    useEffect(() => {
      if ( props.cameraRef ) (props.cameraRef.current = ref.current);
    });

    return (
    <perspectiveCamera
      ref={ref}
      aspect={size.width / size.height}
      {...props} />
    );
  }
