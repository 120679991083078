import React, { useRef, useContext, useEffect, useState } from 'react';
import { PointerIntersectionContext, MeshContext } from '../DandyMesh';
import MaskVerticesCache from './mask-vertices-cache';

import config from '../config.json'
import { Color } from 'three';

export const MAX_MARKER_SIZE = 8;
export const MIN_MARKER_SIZE = 1;

// Save vertex indexes, assume that there are considerable less
// vertices which are marked than total number of vertices.
const maskVertices = new MaskVerticesCache(config.labels);

// https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function useDifference(value) {
  const prev = usePrevious(value) || [];
  const create = value.filter((v) => !prev.includes(v));
  const remove = prev.filter((v) => !value.includes(v));

  return [create, remove];
}

export default function Marker(props) {
  const {
    marker,
    size,
    faceIndex,
    visibleLabels,
    saturation,
    hideAllLabels,
    ...extraProps
  } = props;

  const markerRef = useRef();

  const intersection = useContext(PointerIntersectionContext);
  const meshCtx = useContext(MeshContext);

  const [preview, setPreview] = useState([]);
  const [markLabels, clearLabels] = useDifference(visibleLabels);
  const [baseColorAttr, setBaseColorAttr] = useState();

  const markerId = marker ? marker.id : null;

  // Move active label to the end of the labels list,
  // this way restoreColorByMasks will render active
  // label on top
  const orderedVisibleLabels = visibleLabels.filter((l) => l.id !== markerId);
  const visibleActiveLabel = visibleLabels.find((l) => l.id === markerId);
  visibleActiveLabel && orderedVisibleLabels.push(visibleActiveLabel);

  let scale = 1;
  let position = null;
  if (intersection) {
    scale = intersection.distance * 0.025;
    position = intersection.point;
  }

  useEffect(() => {
    if (!meshCtx.mesh) return;

    const originalColorAttribute =
        meshCtx.mesh.geometry.getAttribute('original_color');

    const colorAttribute =
        meshCtx.mesh.geometry.getAttribute('color');

    const _baseColorAttr = originalColorAttribute.clone();
    for(let vertex = 0; vertex < _baseColorAttr.count; vertex++) {
      let c = new Color(_baseColorAttr.getX(vertex), _baseColorAttr.getY(vertex), _baseColorAttr.getZ(vertex));

      let hsl = c.getHSL({});
      let s = hsl.s + saturation;

      s = Math.max(0.0, s);
      s = Math.min(1.0, s);

      c.setHSL(hsl.h, s, hsl.l);

      _baseColorAttr.setXYZ(vertex, c.r, c.g, c.b);
      colorAttribute.setXYZ(vertex, c.r, c.g, c.b);
    }

    restoreColorByMasks(maskVertices.listAll(), meshCtx.mesh, orderedVisibleLabels, _baseColorAttr);

    colorAttribute.needsUpdate = true;
    _baseColorAttr.needsUpdate = true;

    setBaseColorAttr(_baseColorAttr);

  }, [meshCtx.mesh, saturation]);

  useEffect(() => {
    if (meshCtx.mesh) {
      maskVertices.reset(meshCtx.mesh.geometry);
    }
  }, [meshCtx.mesh]);

  useEffect(() => {
    if (meshCtx.mesh) {
      clearLabels.forEach(label => hideLabel(label, meshCtx.mesh, orderedVisibleLabels, baseColorAttr));
      markLabels.forEach(label => showLabel(label, meshCtx.mesh, orderedVisibleLabels, baseColorAttr));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meshCtx.mesh, markLabels[0], clearLabels[0]]);

  useEffect(() => {
    if (meshCtx.mesh && marker) {
      showLabel(marker, meshCtx.mesh, orderedVisibleLabels, baseColorAttr);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meshCtx.mesh, marker, orderedVisibleLabels]);

  // Marker box position and orientation
  useEffect(() => {
    if (hideAllLabels || !intersection || !meshCtx.mesh) return;

    const lookAt = intersection.face.normal.clone();
    lookAt.transformDirection(meshCtx.mesh.matrixWorld);
    lookAt.multiplyScalar(10);
    lookAt.add(intersection.point);

    if (lookAt && markerRef.current) {
      markerRef.current.lookAt(lookAt);
    }
  }, [intersection, meshCtx, hideAllLabels]);

  let markerColor = null;
  if (intersection && marker) {
    markerColor = intersection.mouse.altKey ? 'white' : marker.color;
  }

  // Mesh coloring, and marker color preview
  useEffect(() => {
    if (!meshCtx.mesh || !baseColorAttr || hideAllLabels) return;

    if (preview) {
      restoreColorByMasks(preview, meshCtx.mesh, orderedVisibleLabels, baseColorAttr);
    }

    if (!intersection) {
      return;
    }

    const buttonDown = intersection.mouse.buttons & 0b001;
    const cameraPan = intersection.mouse.shiftKey;

    if (marker) {
      const colorAttribute = meshCtx.mesh.geometry.getAttribute('color');
      const maskAttr = meshCtx.mesh.geometry.getAttribute(`${markerId}_mask`);

      const face = intersection.face;

      let vertices = [face.a, face.b, face.c];
      expandVertices(vertices, size, faceIndex);

      const alpha = extraProps.alpha;
      const color = new Color(markerColor);
      const erase = intersection.mouse.altKey || extraProps.erase;

      // Render preview
      colorVertices(vertices, color, colorAttribute, 0.75, colorAttribute);
      setPreview(vertices);

      if (buttonDown && !cameraPan) {
        if (!erase) {
          // Color vertices, and set mask value.
          colorVertices(vertices, color, colorAttribute, alpha, baseColorAttr);
          setMaskValue(vertices, maskAttr, alpha ? Math.ceil(alpha * 255) : 1);
          maskVertices.mark(markerId, vertices);
        } else {
          // Erase
          setMaskValue(vertices, maskAttr, 0);
          maskVertices.clear(markerId, vertices);
          restoreColorByMasks(vertices, meshCtx.mesh, orderedVisibleLabels, baseColorAttr);
        }
      }

      colorAttribute.needsUpdate = true;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marker, intersection, meshCtx, visibleLabels]);

  useEffect(() => {
    if (!meshCtx.mesh) return;

    if (hideAllLabels) {
      const vertices = maskVertices.listAll();
      preview.forEach(v => vertices.add(v));
      restoreColorByMasks(vertices, meshCtx.mesh, [], baseColorAttr);
    }
    else {
      restoreColorByMasks(maskVertices.listAll(), meshCtx.mesh, orderedVisibleLabels, baseColorAttr);
    }
  }, [hideAllLabels, baseColorAttr, meshCtx, orderedVisibleLabels, preview]);

  return (
    intersection && !hideAllLabels && (
      <mesh
        ref={markerRef}
        visible={extraProps.visible}
        position={position}
        scale={[scale, scale, scale]}
      >
        <boxGeometry attach="geometry" args={[0.21, 0.21, 7.5]} />
        <meshStandardMaterial attach="material" color={markerColor} />
      </mesh>
    )
  );
}

function restoreColorByMasks(vertices, mesh, visibleLabels, baseColorAttr) {
  if (!mesh || !baseColorAttr) {
    return;
  }

  const colorAttribute = mesh.geometry.getAttribute('color');

  vertices.forEach(vertex => {
    colorAttribute.copyAt(vertex, baseColorAttr, vertex);
  });

  visibleLabels.forEach(({ id : labelId, color : labelColor }) => {
    const maskAttribute = mesh.geometry.getAttribute(`${labelId}_mask`);
    const color = new Color(labelColor);

    vertices.forEach((vertex) => {
      const maskValue = maskAttribute.getX(vertex);
      if (maskValue) {
        colorAttribute.setXYZ(vertex, color.r, color.g, color.b);
      }
    });
  });

  colorAttribute.needsUpdate = true;
}

function hideLabel({ id:labelId }, mesh, visibleLabels, baseColorAttr) {
  restoreColorByMasks(maskVertices[labelId], mesh, visibleLabels, baseColorAttr);
}

function showLabel({ id: labelId }, mesh, visibleLabels, baseColorAttr) {
  restoreColorByMasks(maskVertices[labelId], mesh, visibleLabels, baseColorAttr);
}

function setMaskValue(vertices, maskAttr, value) {
  vertices.forEach((vertex) => {
    maskAttr.setX(vertex, value);
  });
}

function colorVertices(
  vertices,
  color,
  colorAttribute,
  alpha,
  originalColorAttribute
) {
  vertices.forEach((vertex) => {
    let mix = color;

    if (alpha) {
      mix = new Color(
        originalColorAttribute.getX(vertex),
        originalColorAttribute.getY(vertex),
        originalColorAttribute.getZ(vertex)
      );
      mix.lerp(color, alpha);
    }

    colorAttribute.setXYZ(vertex, mix.r, mix.g, mix.b);
  });
}

function expandVertices(vertices, expansionFactor, index) {
  for (let i = 1; i < expansionFactor || 0; i++) {
    const faces = [];

    vertices.forEach((v) => {
      faces.push(...index.getFacesByVertexIndex(v));
    });

    faces.forEach((faceIndex) => {
      [faceIndex * 3, faceIndex * 3 + 1, faceIndex * 3 + 2].forEach((vertexIndex) => {
        const vi = index.getVertexIndex(vertexIndex);
        if (!vertices.includes(vi)) {
          vertices.push(vi);
        }
      });
    });
  }
}
